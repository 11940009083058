import React, { useRef, useState, useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { useMeasure } from 'react-use';
import useArticleShare from '../hooks/use-article-share';
import { useTextSelection } from '../hooks/use-text-selection';
import { getValidCategoryNames, getDataWrapperHeight } from '../model/post';
import { Layout } from '../components/layout';
import { HeadMeta } from '../components/layout/meta';
import { SingleDocumentary } from '../components/articles/single/documentary';
import { SingleArticle } from '../components/articles/single/article';
import { Content, RelatedArticles } from '../components/articles/single';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';

export default function Article({
  data: {
    wpPost: postData,
    allWpPost: { nodes: allArticles },
    wp: {
      siteOptionsMetaboxSettings: { optionsArticleWordsPerMinute },
    },
  },
  pageContext: { referenceCount },
  location,
}) {
  const {
    title,
    content,
    featuredImage,
    categories: { nodes: categories },
    tghpTaxonomyIssues: taxonomyIssues,
    tghpwipIssuePostType: articlePostType,
    tghpwipArticleSmallCapsTitle: useSmallCapsTitle,
    seo,
  } = postData;

  postData.postImage = featuredImage?.node;
  postData.issueName = taxonomyIssues?.nodes[0]?.name;
  postData.validCategories = getValidCategoryNames(categories);

  /**
   * References
   */
  const contentRef = useRef(null);
  const referenceContentRefs = useRef({});
  const referenceSidebarRefs = useRef({});
  const [mainContentMeasureRef, { width: mainContentAreaWidth }] = useMeasure();
  const [referencesAreaMeasureRef, { width: referencesAreaWidth }] = useMeasure();
  const [referenceRowSizesDesktop, setReferenceRowSizesDesktop] = useState([]);
  const [referenceRowSizesMobile, setReferenceRowSizesMobile] = useState([]);

  useEffect(() => {
    window.addEventListener('message', getDataWrapperHeight);

    return () => window.removeEventListener('message', getDataWrapperHeight)
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (!contentRef.current) {
      return;
    }

    const url = new URL(window.location.href);
    const y = url.searchParams.get('y');

    if (y && contentRef.current.children[y]) {
      contentRef.current.children[y].scrollIntoView({ behavior: 'auto' });
    }
  }, [contentRef]);

  useEffect(() => {
    if (
      Object.keys(referenceContentRefs.current).length === 0 &&
      Object.keys(referenceSidebarRefs.current).length === 0
    ) {
      return;
    }

    let articleReferenceListItemOffsets = 0;

    const gridRowsDesktop = Object.values(referenceContentRefs.current)
      .filter((ref) => !!ref)
      .map((articleReferenceInline, i) => {
        if (!referenceSidebarRefs.current[i]) {
          return '';
        }

        const articleReferenceListItem = referenceSidebarRefs.current[i];
        const articleReferenceListItemHeight = articleReferenceListItem.clientHeight;
        const inlineReferenceOffset = Math.max(
          20,
          articleReferenceInline.offsetTop - articleReferenceListItemOffsets,
        );
        articleReferenceListItemOffsets += inlineReferenceOffset + articleReferenceListItemHeight;

        return `${inlineReferenceOffset}px auto`;
      });

    const gridRowsMobile = gridRowsDesktop.map((prevRow, i) =>
      i === 0 ? '0 auto' : 'var(--padding--small) auto',
    );

    setReferenceRowSizesDesktop(gridRowsDesktop);
    setReferenceRowSizesMobile(gridRowsMobile);
  }, [referenceContentRefs, referenceSidebarRefs, mainContentAreaWidth, referencesAreaWidth]);

  const { clientRect, textContent } = useTextSelection(contentRef.current);
  const [isHighlighting, setIsHighlighting] = useState(false);
  const socialUrls = useArticleShare(title, clientRect, textContent);

  const articleContent = (
    <div
      ref={contentRef}
      onMouseDown={() => setIsHighlighting(true)}
      onMouseUp={() => setIsHighlighting(false)}
      role="presentation"
    >
      <Content
        content={content}
        hasReferences={!!referenceCount}
        mainContentMeasureRef={mainContentMeasureRef}
        referenceContentRefs={referenceContentRefs}
      />
    </div>
  );

  /**
   * Article video
   */
  const [showVideo, setShowVideo] = useState(false);

  const onPlayVideo = () => setShowVideo(true);
  const onCloseVideo = () => setShowVideo(false);

  /**
   * 'Copy' social link
   */
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    setIsCopied(true);
    copy(socialUrls.sharedUrl);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <Layout
      location={`single-article--${articlePostType === 'article' ? 'article' : 'documentary'}`}
      issueName={postData.issueName}
      seoData={seo}
      postData={postData}
      onPlayVideo={onPlayVideo}
    >
      <div
        className={classNames([
          'single-article',
          !!Number(useSmallCapsTitle) && 'single-article--small-caps-title',
        ])}
      >
        <section className="single-article__hero">
          {postData.postImage && (
            <GatsbyImage image={postData.postImage.gatsbyImage} alt={postData.postImage.altText} />
          )}
        </section>

        {articlePostType === 'documentary' && (
          <SingleDocumentary
            postData={postData}
            referenceCount={referenceCount}
            location={location}
            articleContent={articleContent}
            socialUrls={socialUrls}
            handleCopyClick={handleCopyClick}
            isCopied={isCopied}
          />
        )}

        {articlePostType === 'article' && (
          <SingleArticle
            postData={postData}
            articleContent={articleContent}
            selectedTextContent={textContent}
            selectedClientRect={clientRect}
            referenceCount={referenceCount}
            referenceRowSizesDesktop={referenceRowSizesDesktop}
            referenceRowSizesMobile={referenceRowSizesMobile}
            referencesAreaMeasureRef={referencesAreaMeasureRef}
            referenceSidebarRefs={referenceSidebarRefs}
            optionsArticleWordsPerMinute={optionsArticleWordsPerMinute}
            socialUrls={socialUrls}
            handleCopyClick={handleCopyClick}
            isCopied={isCopied}
            isHighlighting={isHighlighting}
            showVideo={showVideo}
            onPlayVideo={onPlayVideo}
            onCloseVideo={onCloseVideo}
          />
        )}
        <RelatedArticles postData={postData} allArticles={allArticles} />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    wpPost(slug: { eq: $slug }) {
      title
      slug
      date
      excerpt
      toc
      content
      featuredImage {
        node {
          gatsbyImage(
            width: 4000
            layout: FULL_WIDTH
            placeholder: NONE
            formats: PNG
            quality: 100
            breakpoints: [750, 1080, 1366, 1920, 2500, 3500, 4000]
          )
          altText
        }
      }
      categories {
        nodes {
          name
          slug
        }
      }
      tghpTaxonomyIssues {
        nodes {
          name
          slug
        }
      }
      tghpwipIssuePostType
      tghpwipArticleSpotlighted
      tghpwipArticleAuthor
      tghpwipArticleAudio
      tghpwipArticleVideo
      tghpwipArticleFooterAuthorDetails
      tghpwipArticleFooterEndnotes
      tghpwipArticleRelatedArticlesPosts
      tghpwipArticleSmallCapsTitle
      tghpwipDocumentaryAuthor
      tghpwipDocumentarySubtitle
      tghpwipDocumentaryEpisodes {
        documentaryEpisodesTitle
        documentaryEpisodesVideo
      }
      tghpwipDocumentarySponsors {
        nodes {
          gatsbyImage(layout: CONSTRAINED, width: 80, placeholder: NONE)
        }
      }
      tghpwipReferences {
        text
      }
      ...SeoData
    }

    allWpPost(filter: { slug: { ne: $slug } }) {
      nodes {
        title
        uri
        slug
        excerpt
        databaseId
        categories {
          nodes {
            slug
          }
        }
        tghpwipArticleAuthor
        tghpwipArticleLabel
        tghpTaxonomyIssues {
          nodes {
            slug
          }
        }
      }
    }

    wp {
      siteOptionsMetaboxSettings {
        optionsArticleWordsPerMinute
      }
    }
  }
`;

export const Head = () => <HeadMeta />;
