import React from 'react';
import { Card } from '../card';
import {
  getRelatedArticlesByCategory,
  getRelatedArticlesByIssue,
  getRelatedArticlesManualSelect,
} from '../../../model/post';

const RelatedArticles = ({ postData, allArticles }) => {
  const {
    categories: { nodes: categories },
    tghpwipIssuePostType: articlePostType,
    tghpTaxonomyIssues: taxonomyIssues,
    tghpwipArticleRelatedArticlesPosts: relatedPostsOverride,
  } = postData;

  const issueSlug = taxonomyIssues?.nodes[0]?.slug;
  const firstCategorySlug = categories[0]?.slug;

  const issueRelatedArticles =
    articlePostType === 'article'
      ? getRelatedArticlesByIssue(issueSlug, allArticles)
      : getRelatedArticlesByCategory(firstCategorySlug, allArticles);

  const manuallySelectedArticles = getRelatedArticlesManualSelect(
    relatedPostsOverride,
    allArticles,
  );

  const relatedArticles = manuallySelectedArticles.length
    ? manuallySelectedArticles
    : issueRelatedArticles;

  if (relatedArticles.length === 0) {
    return null;
  }

  return (
    <section className="single-article__related-articles">
      <div className="single-article__related-articles-inner">
        <div className="single-article__related-articles-grid">
          <div className="single-article__related-articles-title">
            {manuallySelectedArticles.length ? 'Related Articles' : 'More articles from this issue'}
          </div>
          <div className="single-article__related-articles-posts">
            {relatedArticles.slice(0, 3).map((article) => (
              <Card post={article} key={article.slug} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default RelatedArticles;
