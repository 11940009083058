import { useCallback, useLayoutEffect, useState } from 'react';

/*
Credit to: https://github.com/juliankrispel/use-text-selection/
 */

function roundValues(_rect) {
  const rect = {
    ..._rect,
  };
  for (const key of Object.keys(rect)) {
    rect[key] = Math.round(rect[key]);
  }
  return rect;
}

function shallowDiff(prev, next) {
  if (prev != null && next != null) {
    for (const key of Object.keys(next)) {
      if (prev[key] !== next[key]) {
        return true;
      }
    }
  } else if (prev !== next) {
    return true;
  }
  return false;
}

/**
 * useTextSelection(ref)
 *
 * @description
 * hook to get information about the current text selection
 *
 */
export function useTextSelection(target) {
  const [clientRect, setClientRect] = useState(null);
  const [textContent, setTextContent] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(null);

  const handler = useCallback(() => {
    let newRect;
    const selection = window.getSelection();

    if (selection == null || !selection.rangeCount) {
      setClientRect(null);
      setTextContent(null);
      setIsCollapsed(null);
      return;
    }

    const range = selection.getRangeAt(0);

    if (target != null && !target.contains(range.commonAncestorContainer)) {
      setClientRect(null);
      setTextContent(null);
      setIsCollapsed(null);
      return;
    }

    if (range == null) {
      setClientRect(null);
      setTextContent(null);
      setIsCollapsed(null);
      return;
    }

    const contents = range.cloneContents();

    if (contents.textContent != null) {
      setTextContent(contents.textContent);
    }

    const rects = range.getClientRects();

    if (rects.length === 0 && range.commonAncestorContainer != null) {
      newRect = roundValues(range.commonAncestorContainer.getBoundingClientRect().toJSON());
    } else {
      if (rects.length < 1) {
        return;
      }
      newRect = roundValues(rects[0].toJSON());
    }

    if (shallowDiff(clientRect, newRect)) {
      setClientRect(newRect);
    }

    setIsCollapsed(range.collapsed);
  }, [target, clientRect]);

  useLayoutEffect(() => {
    document.addEventListener('selectionchange', handler);
    document.addEventListener('keydown', handler);
    document.addEventListener('keyup', handler);
    window.addEventListener('resize', handler);

    return () => {
      document.removeEventListener('selectionchange', handler);
      document.removeEventListener('keydown', handler);
      document.removeEventListener('keyup', handler);
      window.removeEventListener('resize', handler);
    };
  }, [handler]);

  return {
    clientRect,
    isCollapsed,
    textContent,
  };
}
