import { useMemo } from 'react';
import trimCharacters from 'trim-characters';

const useArticleShare = (articleTitle, selectedClientRect, selectedTextContent) => {
  const sharedUrl = useMemo(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const href = window.location.href.replace(window.location.search, '');

    if (!selectedClientRect) {
      return href;
    }

    const firstElemInSelection = document.elementFromPoint(
      selectedClientRect.left,
      selectedClientRect.top,
    );

    if (!firstElemInSelection) {
      return href;
    }

    return `${href}?y=${[...firstElemInSelection.parentElement.children].indexOf(
      firstElemInSelection,
    )}`;
  }, [selectedClientRect]);

  const twitterShareUrl = useMemo(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (!selectedTextContent) {
      return null;
    }

    const ellipsis = '...';
    const tweetContentLength = 280 - window.location.href.length - ellipsis.length - 1; // 1 for space between text and url
    const tweet = trimCharacters(selectedTextContent, tweetContentLength, false, ellipsis);

    return `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweet,
    )}&url=${encodeURIComponent(sharedUrl)}`;
  }, [selectedTextContent, sharedUrl]);

  const whatsappShareUrl = useMemo(() => {
    const shareText = `${encodeURIComponent(selectedTextContent)}%0a${encodeURIComponent(
      sharedUrl,
    )}`;

    return `https://wa.me/?text=${shareText}`;
  }, [selectedTextContent, sharedUrl]);

  const emailShareUrl = useMemo(() => {
    return `mailto:?subject=${articleTitle}&body=${selectedTextContent}`;
  }, [selectedTextContent, articleTitle]);

  const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    sharedUrl,
  )}`;

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    sharedUrl,
  )}`;

  const simpleTwitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    sharedUrl,
  )}`;

  const simpleWhatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(sharedUrl)}`;

  const simpleMatterShareUrl = `https://open.getmatter.com/add-to-queue?contentUrl=${encodeURIComponent(
    sharedUrl,
  )}`;

  const simpleEmailShareUrl = `mailto:?subject=${articleTitle}&body=${encodeURIComponent(
    sharedUrl,
  )}`;

  return {
    sharedUrl,
    twitterShareUrl,
    whatsappShareUrl,
    linkedinShareUrl,
    facebookShareUrl,
    emailShareUrl,
    simpleTwitterShareUrl,
    simpleWhatsappShareUrl,
    simpleMatterShareUrl,
    simpleEmailShareUrl,
  };
};

export default useArticleShare;
